import React from "react";
import ServiceData from "./ServiceData";


const currentURL = window.location.href;

// Use regular expression to extract the value
const regex = /projects\/([^/]+)/;
const match = currentURL.match(regex);

// Check if a match was found and get the value
let projectId = null;
if (match) {
   projectId = match[1];
   console.log(projectId)
}

const ServiceDetail = props => {

  return (
    <div>
        <ServiceData projectId={projectId} />
         
    </div>
  );
};

export default ServiceDetail;
