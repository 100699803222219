import React from "react";
import Intro from './Intro';
import Marquee from "react-fast-marquee";
import   image1 from "./images/keyboard.jpg";
import   mountain from "./images/mountain.jpeg";
import ProjectsData from '../Projects/ProjectsData';
import Clients from "./Clients";
import { Parallax } from "react-parallax";
import TestimonialSlider from './TestimonialSlider';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const techStackTerms = [
    "JavaScript",
    "React",
    "Node.js",
    "Python",
    "Angular",
    "Vue.js",
    "HTML5",
    "CSS3",
    "Sass",
    "MongoDB",
    "SQL",
    "Express.js",
    "Django",
    "DRUPAL",
    "C#",
  
  ];
  const techStackTerms2 = [
    "REST API",
    "GraphQL",
    "Webpack",
    "Redux",
    "Vue.js",
    "TypeScript",
    "SASS/SCSS",
    "Firebase",
    "TensorFlow",
    "PyTorch",
    "D3.js",
    "Bootstrap",
    "Material-UI",
    "Webpack",
    "Elasticsearch",
    
  ]
  const techStackTerms3 = [
    "ASP.NET",
    "Spring Boot",
    "PHP",
    "Laravel",
    "AWS",
    "Docker",
    "Kubernetes",
    "Git",
    "Jenkins",
    "CI/CD",
    "Kibana",
    "Redis",
    "PostgreSQL",
    "MySQL",
  
  ]
  

const Home = () =>{
   
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
          // Delay the scroll to ensure the DOM is fully loaded
          setTimeout(() => {
            const element = document.querySelector(hash);
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }, 100); // Adjust the delay as needed (100ms is usually sufficient)
        }
      }, [hash]);
    return(
        <>
             
            <section id="intro-wrapper" className="">
                <Intro />
            </section>
            <div className="section section-recent-projects border-top">
                <div className="container-full-width">
                    <span className="section-small-heading">  <i className="fa-solid fa-code"></i> WORK.</span>
                    <h2 className="section-title">RECENT PROJECTS.</h2>
                    <div className="projects-items-wrapper">
                        <ProjectsData max_items="6" />

                    </div>

                </div>

            </div>
            <div className="section section-marquee-text border-top-bottom container-full-width">
                <div className="container-full-width">
                    <span className="section-small-heading">  <i className="fa-solid fa-code"></i> SPECIALITIES.</span>

                    <h2 className="section-title">TECH STACKS.</h2>

                </div>
                <div className="border-top-bottom py-5 mx-5">
                    <Marquee direction="right" speed="50">
                        {techStackTerms.map((term, index) => (
                            <div key={index} className="text-item">{techStackTerms.join(" / ")}</div>
                        ))}
                    </Marquee>
                    <Marquee direction="left"  speed="60">
                        {techStackTerms.map((term, index) => (
                            <div key={index} className="text-item">{techStackTerms2.join(" / ")}</div>
                        ))}
                    </Marquee>
                    <Marquee direction="right"  speed="50">
                        {techStackTerms.map((term, index) => (
                            <div key={index} className="text-item">{techStackTerms3.join(" / ")}</div>
                        ))}
                    </Marquee>
                </div>
            </div>
            <Parallax className="parallax-home-wrapper" bgImage={image1} strength={500}>
               <div className="parallax-home" style={{ height: 500 }}>
                   <div className="inner-text">
                        <div className="container-full-width">
                            <span className="section-small-heading text-white">  <i className="fa-solid fa-code"></i> GET IN TOUCH.</span>
                        </div>

                       <div className="container-full-width">
                        <div className="col-lg-8">I don't just CODE, but live for it.</div>
                       </div>

                       <div className="container-full-width mt-5">
                            <span className="section-small-heading text-white"> CONTACT: <a className="text-white" href="mailto:sudarshanhang@gmail.com">sudarshanhang@gmail.com</a></span>
                        </div>
                   </div>
               
               </div>
           </Parallax>


            <div id="section-clients" className="section section-clients border-top">
                <div className="container-full-width">
                    <span className="section-small-heading">  <i className="fa-solid fa-code"></i> CLIENTS.</span>
                    <h2 className="section-title">
                        My Evolutionary Client Network.
                    </h2>
                    <Clients />

                </div>
            </div>

            <section id="client-testimonials" className="section section-testimonials container-full-width border-top-bottom">
                <div className="container-full-width">
                    <div className="small-title">CLIENT TESTIMONIALS.</div>

                    <h2 className="section-title">WHAT MY CLIENTS SAY.</h2>
                    <TestimonialSlider />

                </div>

            </section>

            <Parallax className="parallax-home-wrapper" bgImage={mountain} strength={500}>
               <div className="parallax-home" style={{ height: 500 }}>
                   <div className="inner-text">
                        <div className="container-full-width">
                            <span className="section-small-heading text-white">  <i className="fa-solid fa-code"></i> COLLABORATE.</span>
                        </div>

                       <div className="container-full-width">
                       <div className="col-lg-8">IF YOU CAN IMAGINE IT, WE CAN BUILD IT.</div>
                       </div>
                   </div>
               
               </div>
           </Parallax>
           
        </>

    );
}

export default Home;