import React from 'react';
import Myphoto from './images/myphoto.png'; // Adjust the path to your image file
import icon_react from './images/react.svg';
import icon_drupal from './images/drupal.svg';
import icon_wordpress from './images/wordpress.svg';
import icon_chatgpt from './images/chatgpt.svg';


const  Intro=()=>{
    return(
        <div className="section intro">
            <div className="container-full-width">
              
            
                <div className='row align-items-center'>
                    <div className='intro-section-wrapper'>
                        <div className="photo"> <img id="myphoto" width="40" src={Myphoto} alt="Profile photo" /></div>
                        <div className='right-text-box'>
                            <h1 className='name mb-0 p-0'>Sudarshan Giri.</h1>
                            <h2 className='designation mb-3'>Full-Stack Software Engineer | Web Solutions Architect</h2>
                            <p>8+ years of Experience in Web Development.</p>
                            <p><strong>Email: </strong><a className="text-dark text-decoration-none" href='mailto:sudarshanhang@gmail.com'>sudarshanhang@gmail.com</a></p>
                            <div className="social-media-icons mb-3">
                                <a className="git" href="https://github.com/SudarshanGiri" rel="noreferrer"  target="_blank"><i className="fab fa-github" aria-hidden="true"></i> </a>
                                <a className="linkedin" href="https://www.linkedin.com/in/sudarshan-giri-ba453b152/" target="_blank" rel="noreferrer" > <i className="fab fa-linkedin-in" aria-hidden="true"></i></a>
                            </div>
                            <p  className="col-lg-6"> I help organizations develop secure, scalable web solutions that enhance efficiency, accelerate growth, and unlock new opportunities through AI and innovation.</p>
                            
                            <div className="tech-icons-wrapper pt-3">
                                <span className="item-wrapper"><span><img alt='react icon' width={40} src={icon_react}></img></span>React JS</span>
                                
                                <span className="item-wrapper"><span><img src={icon_drupal} width={40} alt='drupal icon'></img></span>Drupal</span>
                                
                                <span className="item-wrapper"><span><img  alt='wordpress icon' src={icon_wordpress} width={40}></img></span>WordPress</span>
                                
                                <span className="item-wrapper"><span><img alt='chatgpt icon' src={icon_chatgpt} width={40}></img></span>AI</span>
                            </div>
                           

                        </div>

                        
                      
                    </div>
                    
                </div>
                                
                            
                        
                 
                    
            </div>

        </div>
        
    )



}

export default Intro;