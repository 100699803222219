import React, { useState, useEffect } from 'react';
import contentfulConfig from '../../config/contentfulConfig';
import { createClient } from 'contentful';
import Button from '@mui/material/Button';
import PageHeader from "../../components/PageHeader";
import Loading from "../../components/Loading";
const client = createClient({
  space: contentfulConfig.space,
  accessToken: contentfulConfig.accessToken,
  
});
function ServiceData(props) {
    // const serviceId = props.serviceId;

  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [serviceId, setServiceId] = useState(null);


  useEffect(() => {
    const currentURL = window.location.href;

    // Use regular expression to extract the value
    const regex = /services\/([^/]+)/;
    const match = currentURL.match(regex);

    // Check if a match was found and get the value
    let serviceId = null;
    if (match) {
      serviceId = match[1];
    }
    // Fetch data from Contentful
    client
      .getEntry(
         serviceId
        

      )
      .then((response) => {
        setFields(response.fields);
        setLoading(false); // Set loading to false when the data is available
        console.log(response)

      })
      .catch(console.error);
  }, []);
  if (loading) {
    return <Loading />

  }
  let projectImages = [fields.project_images][0];
  let projectKeys = [fields.projectKeys][0];
  return (
    <>
      <PageHeader title={fields.title} description={fields.subTitle} />
      
      <div className='container-full-width'>
        <p className='text-uppercase '><strong>Service Keys</strong> </p>
        <div className='tech-stack-wrapper mb-5'>
            {Array.isArray(projectKeys) && projectKeys.length > 0 ? (
            projectKeys.map((key, index) => (
                <Button key={index} className='mb-3 me-3' variant="outlined">{key}</Button>
            ))
            ) : (
            <p>No service keys available.</p>
            )}
        </div>
    
        <div className='project-images-wrapper'>
            {Array.isArray(projectImages) && projectImages.length > 0 ? (
            projectImages.map((asset, index) => (
                <img key={index} className='featured-image' src={asset.fields.file.url} />
            ))
            ) : (
            <p>No service images available.</p>
            )}
        </div>
      </div>
    </>
  );
  
}

export default ServiceData;
