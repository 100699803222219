import React from "react";
import PageHeader from "../../components/PageHeader";
import EmailIcon from '@mui/icons-material/Email';
const Contact = () =>{
    return(
        <>
            <PageHeader title="CONTACT" description="Shoot me a message with your inquiries and I connect with you." />
            <div className="container-full-width border-top py-5">
                <div className="row">
                    <div className="col-md-6">
                        <p style={{'fontWeight':'300'}} className="display-6">LET'S WORK TOGETHER.</p>
                        <p style={{'fontWeight':'300', 'fontSize':'24px'}} >I would love to discuss your next dream project with you.</p>

                    </div>
                    <div className="col-md-6">
                        <p className="display-6 my-0"><a className="text-decoration-none text-dark" href="mailto: sudarshanhang@gmail.com"><EmailIcon className="me-2"  style={{'fontSize': '60px'}} />sudarshanhang@gmail.com</a></p>

                    </div>

                </div>
             
            </div>
        </>

    );
}

export default Contact;