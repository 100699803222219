import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./NavBar.css";
import { CodeIcon, HamburgetMenuClose, HamburgetMenuOpen } from "./Icons";
// import SiteLogo from '../../images/logo.png'; // Adjust the path to your image file
import { HashLink } from 'react-router-hash-link';


function NavBar() {
  const [click, setClick] = useState(false);
  const location = useLocation();


  const handleClick = () => setClick(!click);
   // Scroll to top whenever the route changes
   useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  return (
    <>
      <nav className="navbar sticky-top">
        <div className="nav-container container-full-width">
          <NavLink exact="true" to="/" className="nav-logo">
            {/* <i className="fas fa-code"></i> */}
            <span className="icon">
              {/* <img className='me-2' width={60} alt="site-logo" src={SiteLogo} /> */}
              <CodeIcon />
              
            </span>
            <span className="logo-text">Sudarshan Giri | <small>Dev Portfolio</small> </span> 

          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact="true"
                activeclassname="active"
                to="/"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            
            <li className="nav-item">
              <NavLink
                exact="true"
                activeclassname="active"
                to="/projects"
                className="nav-links"
                onClick={handleClick}
              >
                Projects
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact="true"
                activeclassname="active"
                to="/services"
                className="nav-links"
                onClick={handleClick}
              >
                Services
              </NavLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#client-testimonials"
                className="nav-links"
                onClick={handleClick}
              >
                Client Testimonials
              </HashLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact="true"
                activeclassname="active"
                to="/contact"
                className="nav-links"
                onClick={handleClick}
              >
                Contact
              </NavLink>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}

            {click ? (
              <span className="icon">
               
                <HamburgetMenuClose />
              </span>
            ) : (
              <span className="icon">
                 <HamburgetMenuOpen />{" "}
              </span>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;